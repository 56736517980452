import { render, staticRenderFns } from "./SurveyComponent.vue?vue&type=template&id=599bcbec&scoped=true&"
import script from "./SurveyComponent.vue?vue&type=script&lang=ts&"
export * from "./SurveyComponent.vue?vue&type=script&lang=ts&"
import style0 from "./SurveyComponent.vue?vue&type=style&index=0&id=599bcbec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599bcbec",
  null
  
)

export default component.exports