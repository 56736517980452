






import Vue from 'vue';
import * as SurveyVue from "survey-vue";
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { mapGetters } from 'vuex';
import { smartToFixed } from '@/common/misc';

const Survey = SurveyVue.Survey;

//TODO: Figure out why tel autocomplete is not working (see https://www.w3schools.com/tags/tryit.asp?filename=tryhtml5_input_autocomplete)
export default Vue.extend({
    name: 'surveyComponent',
    mounted(){
        this.displaySurveyChanges();
    },
    data(){
        return {
            survey: new SurveyVue.Model( this.surveyData ),
            surveyQuestions: [] as {setComment:(s:string)=>void, question:string}[],
            validatedQuestions: [] as SurveyVue.IQuestion[],
            resultMsg: '',
            surveyScorePromise: new Promise(()=>{/**/}) as Promise<number>,
        }
    },
    components: { Survey, },
    props: {
        displaySurvey: {
            type: Boolean,
            default: true,
        },
        submitResults: {
            type: Boolean,
            default: true
        },
        editComments: {
            type: Boolean,
            default: false,
        },
        surveyData: { type: Object },
    },
    watch: {
        surveyData: {
            handler(newVal, oldVal) {
                if(newVal != oldVal){
                    this.displaySurveyChanges();
                }
            },
            deep: true
        },
    },
    methods: {
        displayValidatedSurvey():void {
            const surveyData = JSON.parse( this.surveyData.jsonObj );
            
            const survey = new SurveyVue.Model(surveyData);
            // this.validatedSurvey = survey;
            
            survey.mode = "display";
            survey.questionsOnPageMode = "singlePage";
            survey.data = this.survey.data;
            const corr = this.survey.getCorrectedAnswers();
            const incor = this.survey.getInCorrectedAnswers();
            const total = corr + incor;
            survey.title = `Результаты ${survey.title.charAt(0).toLowerCase() + survey.title.slice(1)}`;
            // this.resultMsg = `Опрос завершен. Верных ответов: ${corr}/${total}`;
            this.survey = survey;

            //TODO: ?Parse survey & display input fields for quiz question. 
            // Save comments alongside other data & display them when user gave an incorrect answer
            this.surveyScorePromise = new Promise((resolveSurveyScore)=>{
                let surveyCalcScores:number[] = [];
                survey
                    .onAfterRenderQuestion
                    .add((survey, options) => {
                        const el:HTMLElement = options.htmlElement;
                        const question:SurveyVue.IQuestion = options.question;
                        const isCorrect = question.isAnswerCorrect();
                        
                        const {correctAnswer} = question as any;
                        const header = el.querySelector('h5');
                        const isRadio = !!el.querySelector('input[value="' + correctAnswer + '"]');
                        const isCheckbox = Array.isArray( correctAnswer );

                        const clrs = {correct:'#a4d1fc',incorrect:'salmon'};
                        const correctAnswers = Array.isArray(correctAnswer) ? correctAnswer : [correctAnswer];
                        const answerEls = ([
                            ...el.getElementsByClassName('sv_q_radiogroup'),
                            ...el.getElementsByClassName('sv_q_checkbox')
                        ] as HTMLElement[]).map((ae)=>{
                            return { 
                                el: ae,
                                input: ae.querySelector('input') as HTMLInputElement,
                                label: ae.querySelector('label') as HTMLLabelElement,
                                score: 0,
                            }
                        });
                        
                        let questionScore = answerEls.reduce((acc,ae)=> {
                            let val = 0;
                            const fraction = 1 / correctAnswers.length;
                            if(ae.input.checked) {
                                val = fraction;
                                const isAnswerCorrect = correctAnswers.includes( ae.input.value )
                                // console.log({acc,isAnswerCorrect})
                                val *= isAnswerCorrect ? 1 : -1;
                                ae.score = smartToFixed( val, 2, Infinity );
                            }
                            acc += val;
                            return acc;
                        },0);
                        // console.log({questionScore});
                        questionScore = Math.min(1, Math.max(0, questionScore));
                        if(isCorrect) questionScore = 1;
                        surveyCalcScores.push(questionScore);
                        if(surveyCalcScores.length == corr + incor) 
                            resolveSurveyScore(surveyCalcScores.reduce((acc,cur)=>acc+cur));

                        if(isRadio || isCheckbox) {
                            const span = document.createElement('span');
                            span.classList.add('question_result');
                            const spanClass = isCorrect ? 'correct' : 'incorrect';
                            span.classList.add(spanClass);
                            span.innerHTML = (isCorrect ? 'Верно' : 'Неверно');

                            const scoreSpan = document.createElement('span');
                            scoreSpan.classList.add('question_score');
                            scoreSpan.classList.add(questionScore % 1 != 0 ? 'partially_correct' : spanClass);
                            scoreSpan.innerHTML = '+' + smartToFixed( questionScore, 2, Infinity );
                            span.appendChild(scoreSpan);

                            if(header) {
                                header.style.backgroundColor = isCorrect ? clrs.correct : clrs.incorrect;
                                header.style.padding = '4px';
                                header.appendChild(span);
                            }
                        }

                        const descriptionEl = el.querySelector('.sv_q_description')
                        console.log({el,descriptionEl, byClass: el.getElementsByClassName('sv_q_description')})
                        if(!isCorrect && descriptionEl) {
                            descriptionEl.classList.add('show');
                        }

                        // console.log({answerEls, correctAnswers})
                        answerEls.forEach((ae)=>{
                            const answerInput = ae.input;
                            const answerLabel = ae.label;
                            const val = answerInput?.value+'';
                            const checked = answerInput.checked;
                            const scoreEl = document.createElement('span');
                            scoreEl.classList.add('answer_score');
                            const displayIsCorrect = (corr:boolean) => {
                                ae.el.style.backgroundColor = corr ? clrs.correct : clrs.incorrect;
                                scoreEl.classList.add(corr ? 'correct' : 'incorrect');
                                scoreEl.innerHTML = (corr ? "+" : "-")+" "+Math.abs(ae.score);
                                if(checked && correctAnswers.length > 1) answerLabel.appendChild(scoreEl)
                            };
                            if(!correctAnswers.includes(val) && checked) displayIsCorrect(false);
                            
                            if(correctAnswers.includes(val)) displayIsCorrect(true);
                        })
                    })
            });
        },
        displaySurveyChanges():void {
            this.survey = new SurveyVue.Model(JSON.parse(this.surveyData.jsonObj));
            (this.surveyEl as HTMLFormElement).setAttribute('autocomplete', 'on');
            this.survey
                .onUpdateQuestionCssClasses
                .add(function (survey, options) {
                    const classes = options.cssClasses;

                    classes.root = "sq-root";
                    classes.title = "sq-title";
                    classes.item = "sq-item";
                    classes.label = "sq-label";

                    if (options.question.getType() === "checkbox") {
                        classes.root += " sq-root-cb";
                    }
                });

            // this.survey
            //     .onAfterRenderQuestion
            //     .add((survey, options) => {
            //             const el:HTMLElement = options.htmlElement;
            //             const question:SurveyVue.IQuestion = options.question;
                        
            //             (question as any).incorrectComment = '';
            //             //TODO: Change surveyComponent structure/data (JSON is passed as a prop, so no changes to it are saved)
            //             const setComment = (s:string) => { (question as any).incorrectComment = s; };
            //             this.surveyQuestions.push( {
            //                 setComment,
            //                 //FIXME: Seems like question.value doesn't store the text question
            //                 question: question.value
            //             } )
            //             if(this.editComments) {
            //                 const commentInput = document.createElement('input');
            //                 commentInput.addEventListener('input', (e) => setComment( (e as any).target.value ) )
            //                 commentInput.placeholder = 'Комментарий при неверном ответе'
            //                 commentInput.style.marginTop = '6px';
            //                 el.appendChild(commentInput);
            //             }
            //     });
            
            this.survey
                .onComplete
                .add(async (sender, options) => {
                    // console.log('Survey is completed', this.surveyData)

                    const corr = sender.getCorrectedAnswers();
                    const incor = sender.getInCorrectedAnswers();
                    let score = Math.round( corr / (corr+incor) * 5);
                    try {
                        this.displayValidatedSurvey();
                    } catch (error) {
                        console.error(error);
                        alert(`При отображении результатов опроса произошла ошибка. Счёт за прохождение: ${score}/5`);
                    }
                    //TODO: test this & some refactoring
                    score = await this.surveyScorePromise;
                    const scoreDecimal = score / (corr+incor) * 5;
                    score = Math.ceil( scoreDecimal );

                    const resultsHtml = `Ваш результат: <br><b style="font-size: 25px;">${Math.round(scoreDecimal/5*100)}% (${score}/5)</b>`;
                    const header = this.surveyEl.getElementsByClassName('sv_header__text').item(0) as HTMLElement;
                    const resultsHeader = document.createElement('h3');
                    resultsHeader.innerHTML = resultsHtml;
                    header.appendChild(resultsHeader);
                    const resultsEl = document.createElement('h3');
                    resultsEl.innerHTML = resultsHtml;
                    this.surveyEl.appendChild(resultsEl);

                    console.log({msg:'Survey is completed', score});
                    
                    if(!this.submitResults) return;

                    const resultsJson = JSON.stringify( sender.data );
                    const response = await ApiService.post(endpoints.postCompleteQuiz(this.surveyData.urlVar), {
                        score,
                        resultsJson,
                    });

                    this.$set( this.user, 'bookChapterScores', response.data.result.user.bookChapterScores) 
                });

            this.survey.onValidateQuestion
                .add((sender, options) => {
                    // const isCorrect = options
                    //     .question
                    //     .isAnswerCorrect();
                    // if (!isCorrect) {
                    //     header.style.backgroundColor = "salmon";
                    //     const radio = options
                    //         .htmlElement
                    //         .querySelector('input[value="' + options.question.correctAnswer + '"]');
                    //     // eslint-disable-next-line no-extra-boolean-cast
                    //     if (!!radio) {
                    //         radio.parentElement.style.color = "green";
                    //     }
                    // }
                    // header.appendChild(span);
                    this.validatedQuestions.push( options.question )
                });
            this.survey.completedHtml = "<h4>Верных ответов <b>{correctedAnswers}</b> из <b>{questionCount}</b>.</h4><br>";
            this.survey.locale = 'ru';
        },
    },
    computed: {
        surveyEl():HTMLElement{
            return ((this.$refs.survey as any).$el as HTMLElement).children.item(0) as HTMLElement;
        },
        ...mapGetters(['user'])
    },
})
